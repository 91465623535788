<!--
 * @Author: zhaojunyan
 * @Date: 2022-03-25 16:29:30
 * @LastEditors: zhaojunyan
 * @LastEditTime: 2022-03-27 17:28:22
-->
<template>
<!-- style="margin: -12px -24px 0;" -->
  <div >
<!--    <div class="page-header">-->
<!--      &lt;!&ndash;      面包屑导航&ndash;&gt;-->
<!--      <BreadCrumb/>-->
<!--      &lt;!&ndash;      打开页面标签&ndash;&gt;-->
<!--&lt;!&ndash;      <TagsView/>&ndash;&gt;-->
<!--      <div class="page-header-detail">-->
<!--        <slot name="page-header-detail"></slot>-->
<!--      </div>-->
<!--    </div>-->
    <div id="page-content" class="page-content">
      <slot/>
    </div>
  </div>
</template>

<script>
import BreadCrumb from '@/components/BreadCrumb'
// import TagsView from '@/layouts/components/TagsView'
export default {
  name:'PageHeaderLayout',
  components: {
    BreadCrumb
  }
}
</script>

<style lang="stylus">
.page-header
  background #fff
  padding 10px 32px 0 10px
  border-bottom 1px solid #e8e8e8
  //border red 1px dashed

.page-content
  // margin 10px 24px 0
  //min-height 90vh
  min-height calc(100vh - 92px)
border-bottom 1px solid #e8e8e8
  overflow-y scroll
  overflow-x hidden
  //width calc(100% + 12px)
  //width calc(100% + 0)

.main-page-content
  min-height calc(100vh - 96px)
</style>

<style lang="scss">

.page-content::-webkit-scrollbar {
  width: 12px; /* 宽度 */
  height: 12px; /* 高度（对于水平滚动条） */
}

/* 滚动条滑块样式 */
.page-content::-webkit-scrollbar-thumb {
  background-color: #FFA1C2 !important; /* 滑块颜色 */
  border-radius: 10px; /* 圆角 */
  border: 3px solid transparent; /* 边框（如果需要的话） */
  background-clip: content-box; /* 使边框不影响背景色的尺寸 */
}

/* 滚动条轨道样式 */
.page-content::-webkit-scrollbar-track {
  background: #f1f1f1; /* 轨道颜色 */
  border-radius: 10px; /* 圆角 */
}

/* 滚动条轨道两侧（或上下两侧对于水平滚动条）的按钮样式，可能不是所有浏览器都支持 */
.page-content::-webkit-scrollbar-button {
  display: none; /* 通常隐藏按钮 */
}

/* 当鼠标悬停在滚动条滑块上时改变样式 */
.page-content::-webkit-scrollbar-thumb:hover {
  background-color: grey; /* 滑块悬停颜色 */
}
/* 针对WebKit浏览器，比如Chrome和Safari */
.page-content::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}

/* 针对IE和Edge */
//.page-content {
//  -ms-overflow-style: none; /* IE 10+ */
//}
</style>
